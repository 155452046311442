import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const eventLogService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
};

function getById(id) {
    const requestOptions = {
        url : '/api/v1/event_log_by_event/' + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll(filter) {
    
    const requestOptions = {
        url : '/api/v1/event_log' + (filter == undefined ? "" : "?limit=10&id="+filter),
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}


function createOrUpdate(jsonData) {

    let formData = new FormData();

    jsonData["ID"] = jsonData["id"]
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : '/api/v1/event_log',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/event_log/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

